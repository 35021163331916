import * as React from 'react'
import styled from 'styled-components'
import { useEffect } from 'react'
import { allowedCodeCharactersRegex } from '../utils/regex'

interface CodeInputProps {
  codeLength: number
  handleValidation: (codeValid: boolean) => void
  setPin: (pin: string) => void
}

const Codecontainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 200px;
  margin: auto;
`

const Input = styled.input`
  width: 30px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  border: none;
  margin: 5px;

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

const CodeInput: React.FunctionComponent<CodeInputProps> = ({
  codeLength,
  handleValidation,
  setPin,
  ...props
}) => {
  const [code, setCode] = React.useState<string[]>(
    new Array(codeLength).fill('')
  )
  const [activeInput, setActiveInput] = React.useState(0)

  const inputs = React.useRef<React.RefObject<HTMLInputElement>[]>(
    [...Array(codeLength)].map(() => React.createRef())
  )

  useEffect(() => {
    const input = inputs.current[0].current as HTMLInputElement
    input.focus()
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    if (!allowedCodeCharactersRegex.test(value)) {
      const input = inputs.current[activeInput].current as HTMLInputElement
      input.value = ''
      return
    }

    const newCode = [...code]
    newCode[activeInput] = value

    setCode(newCode)

    if (activeInput !== codeLength - 1) {
      setActiveInput(activeInput + 1)

      const nextInput = inputs.current[activeInput + 1]
        .current as HTMLInputElement
      nextInput.focus()
    } else {
      const currentInput = inputs.current[activeInput]
        .current as HTMLInputElement

      currentInput.blur()
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== 'Backspace' || activeInput === 0) return

    event.preventDefault()

    const nextIndex =
      activeInput === codeLength - 1 && code[activeInput] !== ''
        ? activeInput
        : activeInput - 1
    const nextInput = inputs.current[nextIndex].current as HTMLInputElement

    nextInput.value = ''

    const newCode = [...code]
    newCode[nextIndex] = ''

    setCode(newCode)

    setActiveInput(nextIndex)

    nextInput.focus()
  }

  const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    event.preventDefault()

    setActiveInput(0)

    inputs.current.forEach(v => {
      const input = v.current as HTMLInputElement
      input.value = ''
    })

    setCode(new Array(codeLength).fill(''))

    const currentInput = inputs.current[0].current as HTMLInputElement

    currentInput.focus()
  }

  React.useEffect(() => {
    setPin(code.join(''))
    if (code.join('').length < codeLength) {
      handleValidation(false)
    } else {
      handleValidation(true)
    }
  }, [setPin, code, codeLength, handleValidation])

  React.useEffect(() => {
    inputs.current[0].current?.focus()
  }, [])
  return (
    <>
      <Codecontainer {...props}>
        {code.map((v, i) => (
          <Input
            key={i}
            pattern="\d*"
            value={code[i]}
            maxLength={1}
            onChange={e => handleChange(e)}
            onKeyDown={e => handleKeyDown(e)}
            onClick={handleClick}
            ref={inputs.current[i]}
            inputMode="numeric"
            data-testid={`pinInput${i}`}
            autoComplete="one-time-code"
          />
        ))}
      </Codecontainer>
    </>
  )
}

export default CodeInput
